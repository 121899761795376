/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

const $ = require('jquery');
// require( 'datatables.net-bs5' )();
//
// var dt = require( 'datatables.net' )( window, $ );
import 'bootstrap';
require('bootstrap-table');

// any CSS you require will output into a single css file (app.scss in this case)
require('../css/app.scss');
require('../css/nav.scss');
require('../css/scoreboard.scss');
require('../css/match.scss');
require('../css/news.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');
